:root {
  --bg-color: #15202b;
  --sec-bg-color: #192734;
  --pri-color: #ffffff;
  --pri-color-light: rgb(136, 153, 166);
  --bor-color: #38444d;

  --sec-color: #4287f5;
  --contrast-color: #eb4034;
  --yellow-color: #fcba03;
  --green-color: #32a852;
  --box-shadow-s: 0 2px 4px rgb(0 0 0 / 7%), 0 4px 5px rgb(0 0 0 / 6%),
    0 1px 10px rgb(0 0 0 / 10%);
  --box-shadow-l: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%);
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--pri-color);
  padding: 10px;
  padding-bottom: 800px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
a,
button,
p,
aside,
tr,
td,
table {
  margin: 0;
  padding: 0;
  line-height: 100%;
}

header h1 {
  padding: 15px;
}

header .sec-button {
  height: 24px;
  width: 24px;
}

header .sec-button .user {
  width: 14px;
  height: 14px;
}

h1 {
  font-size: 24px;
  font-weight: 800;
  font-style: italic;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

h4 {
  font-size: 15px;
  font-weight: 400;
  color: var(--pri-color-light);
}

p {
  font-size: 15px;
  font-weight: 400;
  text-overflow: ellipsis;
  line-height: 1.2;
  text-align: justify;
}

button {
  font-family: "Roboto", sans-serif;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 7px;
}

.button {
  transition: 0.2s;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  font-style: none;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  padding: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 7px;
  margin-top: 10px;
  color: var(--bg-color);
  background: var(--sec-color);
  border: 1px solid var(--sec-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

main {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  width: 100vw;
  left: 0;
  z-index: 200;
  background: var(--bg-color);
  border-bottom: 1px solid var(--bor-color);
}

button {
  cursor: pointer;
}

.right-bar .user {
  width: 16px;
  height: 16px;
  padding: 2px;
}

.user {
  stroke: var(--pri-color-light);
  width: 20px;
  height: 20px;
  stroke-width: 2px !important;
}

.sec-button {
  background: var(--sec-bg-color);
  border-radius: 100%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--bor-color);
  margin-right: 20px;
  transition: 0.2s;
}

.sec-button:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.alpha {
  margin-bottom: 10px;
  align-self: center;
}

.upload {
  transition: 0.2s;
  margin-top: 10px;
  color: white;
  background: var(--sec-color);
  border: 1px solid var(--sec-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.file-drop-modal {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  min-width: 830px;
  max-width: 830px !important;
  max-height: 430px !important;
  padding: 0 !important;
  min-height: 430px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.file-drop-modal .upload {
  transition: none !important;
}

.file-drop-modal p {
  overflow-y: scroll;
  height: 200px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.next-off {
  color: var(--bor-color);
  border: 1px solid var(--bg-color);
  cursor: auto;
  margin-left: auto;
}

.upload:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.content {
  padding: 20px;
  padding-right: 10px;
  background: var(--bg-color);
  width: 100%;
  flex-direction: row;
  overflow: hidden;
}

.deep-dive .content ::-webkit-scrollbar {
  background: var(--bor-color);
}

.past-uploads-wrap {
  padding-right: 5px;
  overflow-x: hidden !important;
  overflow-y: scroll;
  padding-bottom: 25px;
}

header img {
  min-width: 170px;
  max-width: 170px;
  margin-top: -36px;
  margin-left: -4px;
}

header .row {
  margin: 0;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
}

header .row .user {
  height: 14px;
  width: 14px;
}

header .row h3 {
  font-weight: 600;
  margin-right: 5px;
}

.big-logo {
  width: 76.8px;
  height: 20.52px;
  padding-top: 10px;
  padding-bottom: 1px;
  padding-left: 20px;
  margin-bottom: 7px;
  border-bottom: 1px solid var(--bor-color);
  width: 100%;
}

.searchbar {
  display: flex;
  align-items: center;
  margin-right: 9px;
  margin-bottom: 20px;
}

.searchbar .upload {
  margin-top: 0;
  margin-left: 10px;
  height: 44px;
}

.internal-searchbar {
  background: var(--sec-bg-color);
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  border: 1px solid var(--bor-color);
  width: 100%;
}

.bin-anal-content {
  padding: 0;
  overflow: visible;
}

.bin-anal-content .searchbar {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.bin-anal-content .internal-searchbar {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 7px;
}

.bin-anal-content .upload {
  padding: 10px;
  height: 36px;
}

.search {
  stroke: var(--pri-color-light);
  min-width: 20px;
  height: 20px;
  margin-right: 5px;
}

aside {
  position: absolute;
  font-size: 9px;
  font-weight: 500;
  color: var(--pri-color-light);
  top: 5px;
  right: 5px;
}

.sidebar {
  min-width: 180px;
  max-width: 180px;
  border-right: 1px solid var(--bor-color);
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  background: var(--bg-color);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main-row2 {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  height: 100%;
  background: var(--bg-color);
  min-height: calc(100vh - 86px);
  max-height: calc(100vh - 86px);
}

.main-row {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: calc(100vh - 86px);
  max-height: calc(100vh - 86px);
  background: var(--bg-color);
}

.row-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.modal2 h4 {
  text-align: center;
  width: 90%;
}
.modal2 {
  overflow: hidden;
  background: var(--bg-color);
  max-width: 400px;
  max-height: 350px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 10px;
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  position: absolute;
  top: 20px;
  left: 50vw;
  transform: translate(-50%, 0);
  border: 1px solid var(--bor-color);
  animation-duration: 0.2s;
  animation-name: slidein;
}

.modal2 .upload {
  margin-right: 0px !important;
}

@keyframes slidein {
  from {
    opacity: 0%;
    transform: translate(-50%, 0) scale(0%);
  }
  to {
    opacity: 100%;
    transform: translate(-50%, 0) scale(100%);
  }
}

.modal-bg {
  min-width: 100vw;
  z-index: 200 !important;
  backdrop-filter: blur(5px);
  min-height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.2);
  }
}

.modal .row {
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}

.modal .sec-button {
  margin-left: 0px;
}

.modal h2 {
  font-size: 16px;
  margin-left: 16px;
}

.modal .upload-icon {
  margin: 3px;
}

.modal .upload {
  margin-top: 0px;
  margin-left: auto;
  margin-right: 27px;
}

.filter {
  margin-top: 0px;
  width: calc(100vw - 525px);
  padding-top: 40px;
  min-height: calc(100vh - 126px);
  max-height: calc(100vh - 126px);
  border-left: 1px solid var(--bor-color);
  background: var(--bg-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

.deep-dive .filter {
  width: calc(50vw - 60px);
}

.filter-internal {
  overflow-y: scroll;
  padding: 30px;
  overflow-x: hidden;
  padding-bottom: 0;
  margin-top: -3px;
  height: 100%;
}

#drag-w {
  position: absolute;
  left: -4px;
  top: 0;
  bottom: 0;
  width: 20px;
  cursor: w-resize;
  height: 100%;
}

#drag-w:hover::after {
  content: "";
  background: var(--sec-color);
  width: 5px;
  height: 100%;
  position: absolute;
}

.filter table {
  border-top: 1px solid var(--bor-color);
  border-bottom: 1px solid var(--bor-color);
  width: calc(100% + 40px);
  transform: translateX(-20px);
}

.filter table th {
  background: var(--sec-bg-color);
}

.filter table p {
  overflow-y: auto !important;
  max-height: none !important;
}

.filter .entropy {
  width: calc(100% - 40px) !important;
  margin-bottom: 10px;
}

.past-upload .CWSS-chart-holder-prev {
  height: 100px !important;
  margin: 0;
  width: 100px !important;
}

.right-bar {
  border-left: 1px solid var(--bor-color);
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  z-index: 100;
}

.right-bar .user {
  cursor: pointer;
  height: 20px;
  stroke-width: 1.5px !important;
}

.right-bar .user:hover {
  stroke: var(--sec-color);
}

.filter h2,
.fullreport h2 {
  font-size: 16px;
  font-weight: 600;
}

.report-prev {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  background: var(--bg-color);
}

.info {
  position: absolute;
  width: 12px;
  height: 12px;
  stroke: var(--pri-color-light);
  top: 5px;
  right: 5px;
}

.upload-header {
  margin-bottom: -5px;
  font-weight: 700;
  margin-left: 5px;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-header::selection {
  color: none;
  background: none;
}

.light-r {
  background: var(--contrast-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 12px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.light-y {
  background: var(--yellow-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 12px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.light-g {
  background: var(--green-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 12px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.light-gr {
  background: var(--pri-color-light);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 12px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.report-prev .row-2 {
  margin-top: 0px;
  align-items: center;
  padding: 0;
  justify-content: center;
}

h3 {
  font-size: 14px;
  font-weight: 400;
  color: var(--pri-color-light);
}

.view-toggle {
  stroke: var(--pri-color-light);
  width: 14px;
  height: 14px;
  margin-left: 0;
  margin-right: 0px;
  margin-top: 6px;
  margin-bottom: 1px;
  cursor: pointer;
}

.view-toggle:hover {
  stroke: var(--sec-color);
}

.view-toggle-selected {
  stroke: var(--sec-color);
  width: 17px;
  height: 17px;
  margin-top: 6px;
  margin-bottom: 1px;
  align-self: center;
  cursor: pointer;
}

input {
  outline: none;
  border: none;
  margin-top: 3px;
  padding: none;
  background: none;
  font-size: 15px;
  font-weight: 500;
  color: var(--pri-color);
  font-family: "Roboto", sans-serif;
  width: 100%;
}

::placeholder {
  font-weight: 400;
  color: var(--pri-color-light) !important;
}

th {
  font-size: 15px;
  border-bottom: 2px solid var(--sec-bg-color);
  padding: 15px;
  height: 24px;
}

td {
  font-size: 15px;
  padding: 15px;
}

table {
  align-self: center !important;
  margin-top: 10px;
  border-collapse: collapse;
}

.t-1 {
  border-right: 2px solid var(--sec-bg-color);
  text-align: left;
  white-space: pre-line;
  line-height: 1.4;
}

.fullreport .t-1 {
  width: 160px;
}

.t-2 {
  text-align: left;
}

.t-22 {
  border-right: 2px solid var(--sec-bg-color);
  width: 90px;
  text-align: left;
}

.t-3 {
  width: 340px;
  text-align: left;
}

.t-3 p {
  text-align: left;
  overflow-y: scroll !important;
}

.t-4 {
  text-align: left;
  width: 90px;
  border-right: 2px solid var(--sec-bg-color);
}

.reports-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
}

.filter h4,
.fullreport h4 {
  font-size: 15px;
  font-weight: bold;
  color: var(--pri-color);
  margin-top: 10px;
}

.toggle-header {
  margin-top: 2px;
  width: 100%;
  height: 35px;
  background: var(--bg-color);
  border-bottom: 1px solid var(--bor-color);
  position: relative;
  display: flex;
  flex-direction: row;
}

.filter .toggle-header {
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-header-item {
  display: flex;
  padding: 10px;
  flex-direction: row;
  border-right: 1px solid var(--bor-color);
  cursor: pointer;
  margin-top: -1px;
  position: relative;

  z-index: 190 !important;
}

.toggle-header-item-dd {
  z-index: 90 !important;
}

.toggle-header-item h3 {
  font-size: 14px;
  margin-top: 2px;
  margin-right: 5px;
  font-weight: 600;
  color: var(--pri-color);
}

.toggle-header-item h4 {
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  color: var(--pri-color-light);
}

.toggle-header-item:hover {
  background: var(--sec-bg-color);
}

.sel-toggle-header-item {
  border-top: 3px solid var(--sec-color);
  cursor: auto;
  z-index: 10;
}

.sel-toggle-header-item:hover {
  background: none;
}

.toggle-header-popup {
  position: fixed;
  width: 300px;
  height: 400px;
  background: var(--bg-color);
  box-shadow: rgba(99, 99, 99, 0.3) 4px 4px 8px 0px;
  left: 181px;
  top: 77px;
  overflow-y: scroll;
  padding: 20px;
}

.blocker {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  background: var(--bg-color);
  width: 100%;
}

.fullreport-wrap {
  padding-bottom: 500px;
  padding: 20px;
  width: calc(100% - 41px);
  align-items: center;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  overflow-x: hidden;

  position: relative;
}

.page {
  padding: 60px;
  max-width: 550px;
  min-width: 550px;
  max-height: 827px;
  min-height: 827px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: var(--bg-color);
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.page-no-shadow {
  box-shadow: none;
}

::-webkit-scrollbar {
  background: var(--bor-color);
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--pri-color-light);

  border-radius: 2px;
}

.side-buttons {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: row;
  margin-top: -1px;
}

.side-buttons .upload {
  border-radius: 100%;
  background: none;
  border: none;
  margin: 0 !important;
  padding: 0px !important;
  height: 100%;
  border-left: 1px solid var(--bor-color);
  border-radius: 0;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}

.side-buttons .upload:hover {
  box-shadow: none !important;
  background: var(--sec-bg-color);
}

.side-buttons .upload h1 {
  margin-top: 6px;
  margin-left: 1px;
  color: var(--pri-color-light);
}

th {
  text-align: left;
}

.edit-icon {
  height: 16px;

  margin-right: 1px;
  margin-top: 1px;
  font-style: normal;
  font-size: 14px;
  margin-left: 0px;
  color: var(--pri-color-light);
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 40;
}

#aegis {
  left: 40px;
  top: 0px;
}

#anno-canvas {
  z-index: 100;
}

.side-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: -16px;
  color: var(--pri-color-light);
  text-decoration: none;
  position: relative;
}

.side-link:hover {
  background: var(--sec-bg-color);
}

.selected-link {
  background: var(--sec-bg-color);
  color: var(--sec-color);
}

.unselected-link {
  color: var(--bor-color);
  cursor: default;
}

.unselected-link:hover {
  background: none;
}

.link-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  margin-left: 35px;
  stroke-width: 1.75px;
}

.side-link h5 {
  text-decoration: none !important;
  margin: 0;
  font-size: 14px;
}

.sidebar .upload {
  margin-bottom: 10px;
  width: 140px;
  padding-left: 5px;
  padding-right: 5px;
  align-self: center;
  margin-left: none;
}

.upload-color-g {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--green-color);
  position: absolute;
  top: 5px;
  left: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.upload-color-y {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--yellow-color);
  position: absolute;
  top: 5px;
  left: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.upload-color-r {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--contrast-color);
  position: absolute;
  top: 5px;
  left: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.CWSS-chart {
  position: static;
}

.CWSS-chart-2 {
  position: static;
}

.fullreport .CWSS-chart-holder {
  height: 200px;
  width: 200px;
}

.CWSS-chart-holder {
  height: 240px;
  width: 240px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: 10px;
  position: relative;
}

.small-chart {
  height: 60px;
  width: 60px;
}

.past-upload .CWSS-chart-holder {
  height: 70px;
  width: 70px;
  margin-top: -15px;
}

.CWSS-chart-holder-bar {
  height: 200px;
  width: 220px;
}

.light-explain {
  display: flex;
  flex-direction: column;

  margin-right: auto;
  align-self: flex-start;
}

.prev-file-name {
  align-self: flex-start;
  font-weight: 700 !important;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chartt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-title {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-title h2 {
  margin-bottom: 4px;
}

.row-2 h2 {
  font-weight: 800;
}

.row-2 h3 {
  font-weight: 500;
}

.row-3 {
  display: flex;
  flex-direction: row;
}

.row-3 .chartt {
  padding-right: 30px;
  padding-left: 0px;
  padding-top: 0px;
}

.row-3 p {
  margin-top: 10px;
}

/* Link Style 4 */

.t-headdd {
  border-top: none;
}

tr {
  border-top: 1px solid var(--sec-bg-color);
}

.cwss-score {
  font-weight: 600 !important;
}

.upload-table {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;

  border: 1px solid var(--bor-color);
}

.upload-table td {
  border-right: 1px solid var(--bor-color);
  min-width: 13% !important;
  max-width: 13% !important;
  width: 13% !important;
  overflow: hidden;
  word-break: break-all;
}

.upload-table td:nth-of-type(1) {
  min-width: 20% !important;
  max-width: 20% !important;
  width: 20% !important;
}

.upload-table tr {
  cursor: pointer;
}

.upload-table tr:hover {
  background: var(--sec-bg-color);
}

.upload-table-header {
  border-top: none;
  cursor: default !important;
}

.red-table .upload-table-header:hover {
  background: #fff3f8;
}

.red-table .upload-table-header {
  background: #fff3f8;
}

.yellow-table .upload-table-header:hover {
  background: lightyellow;
}

.yellow-table .upload-table-header {
  background: lightyellow;
}

.green-table .upload-table-header:hover {
  background: #cdfbe4;
}

.green-table .upload-table-header {
  background: #cdfbe4;
}

.blue-table .upload-table-header:hover {
  background: #e0f1ff;
}

.blue-table .upload-table-header {
  background: #e0f1ff;
}

.fullreport table {
  width: 100%;
  border: 1px solid var(--bor-color);
}

.new-upload {
  box-shadow: var(--sec-color) 0px 0px 3px 0px, var(--sec-color) 0px 0px 3px 0px !important;
}

.analyzing-uploads {
  margin-top: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-popup {
  margin-top: 10px;
  width: 120px;
  left: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  z-index: 10;
  border: 1px solid var(--bor-color);
  background: var(--sec-bg-color);
  overflow: hidden;
}

.progress-popup h2 {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  width: 80px;
  text-overflow: ellipsis;
}

.progress-popup h3 {
  font-size: 11px !important;
}

.progress-popup .CWSS-chart-holder {
  margin-top: -10px;
  margin-left: -5px;
  margin-right: 5px;
  width: 40px;
  height: 40px;
}

@keyframes slide-left {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: none;
  }
}

.cwe-locations div {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  max-width: 100%;
}

.cwe-locations div h3:nth-child(2) {
  margin-left: auto;
}

.cwe-locations {
  position: absolute;
  top: -1px;
  left: 205px;
  min-width: 72px;
  max-width: 72px;
  overflow: hidden;

  overflow-y: scroll;
  max-height: 200px;
  display: flex;
  flex-direction: column;

  gap: 20px;
  transform: translateY(-100%);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
  z-index: 40;
  border: 1px solid var(--bor-color);
  background: var(--sec-bg-color);
}

.help-text {
  margin-top: 15px;
}

.create-user-modal {
  border: none !important;
}

.pincodes-modal {
  display: flex;
  flex-direction: column;
}

.pincodes-modal .left-row h4 {
  padding-left: 0 !important;
  text-align: left !important;
}

.pincodes input {
  background: var(--sec-bg-color);
  padding: 10px;
  border-radius: 5px;
  width: calc(100% - 22px);
  border: 1px solid var(--bor-color);
}

a {
  cursor: pointer;
  font-weight: 500;
}

.release-notes {
  margin-left: auto;
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--pri-color-light) !important;
  margin-right: 5px !important;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.release-notes:hover {
  text-decoration: underline;
}

.flash-alert {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  color: var(--pri-color-light) !important;
  stroke: var(--pri-color-light);
  cursor: pointer;
}

.fullreport input {
  border-bottom: 1px solid var(--bor-color);
  margin-left: 5px;
  width: 300px;
  padding-bottom: 4px;
}

.filter input {
  margin-left: 5px;
  width: 100%;
}

.search-row {
  display: flex;
  align-items: center;
  color: var(--pri-color-light);
}

.filter .search-row {
  margin-left: -10px;
}

.search-row .user-icon {
  height: 18px;
}

/* Dashboard */
.flash-alerts {
  width: 300px;
  padding: 20px;
  padding-right: 0px;
}

.flash-alerts h2,
.schedule h2 {
  font-size: 14px;
  font-weight: 600 !important;
}

.schedule {
  width: 300px;
  height: 400px;
  padding: 20px;
  padding-top: 0px;
  padding-right: 0px;
}

.flash-alert-list-item {
  background: var(--sec-bg-color);
  padding: 10px;
  display: flex;
  border-radius: 5px;
  margin-right: 20px;
  margin-top: 10px;
}

.red-bg {
  background: #fff3f8 !important;
}

.yellow-bg {
  background: lightyellow !important;
}

.flash-alert-list-item h3 {
  width: 80%;
  margin-right: auto;
  color: var(--pri-color);
  font-weight: 400;
}

.flash-alert-list-item h4 {
  font-size: 9px;
  font-weight: 500;
}

.version-history-file {
  font-size: 16px;
  font-weight: 700;
}

.dash-content h3 {
  margin-bottom: 20px;
}

.version-history {
  position: relative;

  border: 1px solid var(--bor-color);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  max-width: 500px;
  min-width: 300px;
  overflow: hidden;
}

.version-history h2 {
  font-size: 15px;
  margin-right: 5px;
  font-weight: 600;
}

.add {
  margin-left: auto;
  color: var(--contrast-color) !important;
  font-weight: 600 !important;
}

.sub {
  margin-left: auto;
  color: var(--green-color) !important;
  font-weight: 600 !important;
}
.version-history h3 {
  margin-bottom: -1px;
}
.version-history li h3 {
  margin-bottom: 0px;
  line-height: 1.2;
  font-weight: 400;
  color: var(--pri-color);
}

.version-history li {
  display: flex;
}
.version-history ul {
  padding: 20px;
  margin: 0px;
}

.version-history .row {
  justify-content: flex-start;
  align-items: center;
  background: var(--sec-bg-color);
  border-bottom: 1px solid var(--bor-color);
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.version-history .row .upload {
  padding: 5px;
  margin: 0px;
  margin-left: auto;
  margin-right: 10px;
}

.report-ic {
  height: 15px;
  width: 15px;
}

.dash-content {
  border-right: 1px solid var(--bor-color);
  padding-right: 20px;
}

.version-foot {
  border-top: 1px solid var(--bor-color);
}

.version-foot h3 {
  margin-left: 2px;
  font-size: 15px;
  margin-top: -1px;
}

.version-foot .row {
  border: none;
  padding: 10px;
  border-right: 1px solid var(--bor-color);
  width: 40px;
  background: var(--bg-color);
  justify-content: center;
  cursor: pointer;
}

.version-foot .row:hover {
  background: var(--sec-bg-color);
}

.version-foot .report-ic {
  stroke: var(--pri-color-light);
}

.dash-prev {
  margin-right: 0px;
}

.remediation-request {
  padding: 20px;
  border-left: 2px solid var(--bor-color);
  margin-left: 20px;
  position: relative;
}

.remediation-request h2 {
  font-size: 15px;
  margin-right: 5px;
  font-weight: 600;
}

.remediation-request .row {
  justify-content: flex-start;
  margin-bottom: 4px;
}

.remediation-request h3 {
  margin-top: 1px;
  margin-bottom: 0px;
}

.remediation-request .icon-holder {
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  border-radius: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px !important;
  width: 16px;
  height: 16px;
  top: 20px;
  left: -16px;
}

.remediation-request ul {
  margin-top: 5px;
  margin-bottom: 0px;
}

.remediation-request li {
  font-size: 14px;
  cursor: pointer;
  color: var(--sec-color);
  text-decoration: underline;
  font-weight: 600;
}

.remediation-request .icon-holder .report-ic {
  width: 16px;
  height: 16px;
  stroke: var(--pri-color-light);
}

.rem-list-item {
  border: 1px solid var(--bor-color);
  cursor: pointer;
}

.selected-rem {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.rem-list-item div {
  margin-right: auto;
}

.rem-list-item .light-r,
.rem-list-item .light-y,
.rem-list-item .light-g,
.rem-list-item .light-gr {
  margin-right: 10px;
}

.rem-warn-row {
  align-items: center;
  justify-content: flex-start;
}

.rem-warn-row h3 {
  margin: 0px;
  margin-top: 4px;
  margin-left: auto;
  font-weight: 600;
}

.lockout-span {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

header span,
.lockout-span,
.file-drop-modal span {
  cursor: pointer;
  color: #343139;
  text-decoration: underline;
  color: var(--sec-color);
  font-weight: 500;
  box-shadow: inset 0 px 0 var(--sec-color);
  transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1),
    color 270ms cubic-bezier(0.77, 0, 0.175, 1);
  margin-left: auto;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--pri-color-light);
  text-decoration: none;
}

footer span {
  cursor: pointer;
  color: #343139;
  text-decoration: underline;
  color: var(--sec-color);
  font-weight: 500;
  box-shadow: inset 0 px 0 var(--sec-color);
  transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1),
    color 270ms cubic-bezier(0.77, 0, 0.175, 1);

  text-decoration: none;
}

.release-notes-div {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.release-notes-div ::-webkit-scrollbar {
  background: var(--bor-color);
}

.filter ::-webkit-scrollbar-track {
  background: var(--bor-color) !important;
}

.release-notes-div h2 {
  margin: 0 !important;
  font-size: 14px;
}

.release-notes-div .row {
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
}

.cwe-catas {
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.cwe-catas h4 {
  width: auto;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 600;
  padding-bottom: 4px;
  color: var(--pri-color-light);
}

.release-notes-div .row h4 {
  width: auto;
  margin-right: 20px;
  cursor: pointer;
  font-weight: 600;
  padding-bottom: 4px;
}

.release-notes-div ul {
  height: 180px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 10px;
}

.release-notes-div li {
  font-size: 14px;
  margin-bottom: 10px;
}

.cwe-catas .selected-rel {
  transform: translateY(2px);
}

.selected-rel {
  color: var(--sec-color) !important;
  margin-top: 4px;
  padding-bottom: 4px;
  border-bottom: 4px solid var(--sec-color);
}

.release-notes-div .upload {
  margin-left: auto;
  margin-right: 0;
}

.head-row {
  margin: 0px !important;
}

.head-row img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
}

.login-page-rel {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  border: 1px solid var(--bor-color);
}

.drop-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  justify-content: flex-start;
  padding: 10px;
}

.drop-upload h2 {
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
}

.cancel-up {
  margin-left: auto;
  margin-right: 0px;
  height: 14px;
  width: 14px;
  cursor: pointer;
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  border-radius: 100%;
  padding: 4px;
  stroke: var(--pri-color-light);
}

.left-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  margin-bottom: 5px;
  padding-left: 20px;
}

.left-row .sec-button,
.managed-user .sec-button {
  height: 20px;
  width: 20px;
  margin-left: auto;
}

.left-row .sec-button .user,
.managed-user .sec-button .user {
  height: 14px;
  width: 14px;
}

.managed-user .sec-button {
  border: none;
  background: none;
  height: 22px;
  width: 22px;
}

.manage-users {
  padding-right: 0px;
  padding-left: 0px;
}

.managed-user {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
}

.managed-user .upload {
  margin: 0px;
  margin-right: 10px;
  height: 26px;
  width: 26px;
  padding: 0px;
}

.managed-user h2 {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 400 !important;
}

.managed-user h3 {
  font-size: 1px;
}

.managed-user .sec-button {
  height: 20px;
  width: 20px;
  margin-left: auto;
}

.left-row .sec-button .user,
.device-graph-item .sec-button .user {
  height: 14px;
  width: 14px;
  background: none;
}

.react-flow__node-default {
  background: transparent !important;
}

.device-graph-item .sec-button {
  border: none;
  background: none;
  height: 22px;
  width: 22px;
}

.device-graph-item {
  padding-bottom: 10px;
  border-radius: 5px;
  display: flex;
  background: var(--bg-color);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 3px solid var(--contrast-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.device-graph-item .upload {
  margin: 0px;
  margin-right: 10px;
  height: 26px;
  width: 26px;
  padding: 0px;
}

.device-graph-item-header {
  background: var(--contrast-color);
  padding: 5px;
  width: 96%;
}

.device-graph-item-header h3 {
  color: var(--bg-color);
  text-align: left;
}

.device-graph-item h2 {
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  font-weight: 400 !important;
}

.device-graph-item .row {
  width: 96%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  overflow: hidden;
  justify-content: flex-start;
}

.device-graph-item div {
  align-items: flex-start;
}

.device-graph-item h3 {
  font-size: 1px;
}

.zero-sch {
  background: none;
  border: 1px dashed var(--pri-color-light);
  color: var(--pri-color-light);
  box-shadow: none;
  cursor: default;
}

.zero-sch:hover {
  box-shadow: none !important;
}

.odd {
  background: var(--sec-bg-color);
}

.calendar {
  margin-top: 0;
}

.calen-content {
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
}

.calen-content .rem-warn-row {
  margin-left: 20px;
  margin-right: 20px;
}

.calen-content .rem-warn-row {
  margin-left: 20px;
  margin-right: 20px;
}

.sch-tot {
  margin-left: 20px;
}

.calendar h2 {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.calendar td h2:nth-of-type(1) {
  color: var(--sec-color);
  font-weight: 800;
}

.calendar .th {
  background: var(--sec-bg-color);
  padding: 0px;
  border-top: none;
}

.calendar .th td {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: none;
}

.calendar-container {
  border-top: 1px solid var(--bor-color);
  border-left: 1px solid var(--bor-color);
  border-right: 1px solid var(--bor-color);
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow-x: scroll !important;
  width: calc(100% + 2px);
  transform: translateX(-1px);
  max-height: calc(100vh - 109px);
}

.calendar td {
  padding-top: 87px;
  min-height: 100px;
  border-right: 1px solid var(--bor-color);
  position: relative;
}

.sch-ass {
  position: absolute;
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  width: 238px;
  height: 52px;
  left: 10px;
  top: 10px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-left: 10px;
}

.sch-icon {
  stroke: var(--pri-color-light);
  width: 27px;
  height: 27px;
  margin-right: 10px;
}

.sch-ass h3 {
  color: var(--pri-color);
  margin: 0;
  text-align: left;
  font-weight: 500;
}

.sch-ass h4 {
  margin: 0;
  text-align: left;
  font-size: 14px;
}

.sch-ass .light {
  margin-left: auto;
  margin-right: 10px;
  border-radius: 100%;
  background: var(--contrast-color);

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.sch-ass .upload {
  padding: 5px;
  margin: 0px;
}

.sch-ass .report-ic {
  height: 15px;
  width: 15px;
}

.no-report {
  background: none !important;
  color: var(--pri-color-light);
  box-shadow: none;
  border: 1px dashed var(--pri-color-light);
}

.logout-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout-modal .upload {
  margin: 0;
  width: 120px;
}

.logout-modal .next-off {
  margin: 0;
  margin-top: 5px;
  width: 120px;
  cursor: pointer;
  color: var(--bor-color);
  transition: 0.2s;
}

.logout-modal .next-off:hover {
  color: var(--pri-color-light);
}

.cancel-writing {
  background: var(--contrast-color) !important;
  border: 1px solid var(--contrast-color);
  position: relative;
}

.cancel-writing .edit-icon {
  stroke: var(--bg-color) !important;
}

.mini-cancel {
  position: absolute;
}

.link-timeout {
  background: var(--green-color);
  border: 1px solid var(--green-color);
}

.scanned-devices {
  background-image: radial-gradient(var(--bor-color) 20%, transparent 20%);
  background-position: 0 0, 80px 80px;
  background-size: 10px 10px;
}

.device-list {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  width: 300px;
  background: var(--bg-color);
  position: absolute;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
}

.device-list h2 {
  font-size: 14px;
}

.managed-user h2 {
  font-size: 15px !important;
}

.device-list .left-row {
  margin-bottom: 10px;
}

.y-s {
  margin-right: 10px;

  height: 25px;
  width: 25px;
}

.r-s {
  margin-right: 10px;

  height: 25px;
  width: 25px;
}

.g-s {
  margin-right: 10px;

  height: 25px;
  width: 25px;
}

.left-mar {
  margin-left: auto !important;
}

.device-list .managed-user {
  padding-right: 10px;
}

.folder-graph-item {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid var(--bor-color);
}

.folder-graph-item h2 {
  font-size: 15px;
  margin-top: 2px;
  margin-left: -3px;
  color: var(--pri-color-light);
}

.settings-list {
  border: 1px solid var(--bor-color);

  min-width: 200px;
  max-width: 200px;
  height: fit-content;
  padding-top: 15px;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  .settings-list {
    display: none;
  }
  .setting-man-u {
    min-width: calc(100vw - 181px) !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.settings-list h2 {
  font-size: 14px;
}

.settings-list .left-row {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--bor-color);
  margin-bottom: 0;
}

.settings-list .managed-user {
  padding-right: 10px;
  position: relative;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bor-color);
}

.settings-list .managed-user:hover {
  background: var(--sec-bg-color);
}

.settings-list .managed-user:last-of-type {
  border-bottom: none;
  margin-bottom: 0px;
}

.setting-selected {
  background: var(--sec-color);
  border-radius: 15px;
  width: 4px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 11px;
}

.sel-set {
  background: var(--sec-bg-color);
}

.sel-set h2,
.sel-set .user {
  color: var(--sec-color) !important;
  stroke: var(--sec-color) !important;
}

.settings-list .managed-user h2 {
  font-size: 14px !important;
  color: var(--pri-color-light);
}

.settings-list .user {
  height: 20px;
  width: 20px;
  stroke: var(--pri-color-light);
  stroke-width: 1.75 !important;
}

.man-user-title {
  font-size: 15px !important;
}

.real-man-u-t {
  margin-top: 0px !important;
  margin-left: 5px !important;
}

.setting-man-u {
  padding-left: 5px;
  width: 100%;

  padding-top: 0px;
  max-width: 600px;
  justify-self: center;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: calc(100vw - 388px);
  padding-right: 20px;
}

.setting-man-u .left-row {
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0;
}

.users-being-managed {
  border: 1px solid var(--bor-color);
  border-radius: 10px;
  position: relative;
}

.users-being-managed .row {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.users-being-managed .managed-user {
  padding: 0;
  border-top: 1px dashed var(--bor-color);
  overflow: hidden;
}

.users-being-managed .managed-user div:nth-of-type(1) {
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
}

.users-being-managed .managed-user div:nth-of-type(1) h2 {
  font-size: 15px;
  font-weight: 500 !important;
  min-width: 33% !important;
  padding-right: 10px;
  overflow: hidden;
}

.users-being-managed .managed-user div:nth-of-type(1) h3 {
  font-size: 14px;
  font-weight: 400 !important;
  text-align: left !important;
  overflow: hidden;
  min-width: 33% !important;
  padding-right: 10px;
}

.setting-man-u .sec-button {
  min-width: 50px;
  padding: 10px;
  border-radius: 0;
  border-left: 1px solid var(--bor-color);
  margin-right: 0;
}

.setting-man-u .upload {
  margin: 0;
  margin-left: 0px;
}

.users-being-managed .managed-user .sec-button:nth-of-type(2) {
  min-width: 140px;
}

.users-being-managed .managed-user .sec-button:nth-of-type(3) {
  margin-left: 0;
  min-width: 90px;
}
.users-being-managed .managed-user .sec-button:nth-of-type(4) {
  margin-left: 0;
  min-width: 65px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.users-being-managed .managed-user:first-of-type {
  border-top: none !important;
}

.setting-man-u .sec-button:hover {
  box-shadow: none;
  background: var(--sec-bg-color);
}

.setting-man-u .sec-button h3 {
  margin-right: 5px;
  font-size: 14px;
}

.setting-man-u .sec-button .user {
  width: 12px;
  height: 12px;
}

.setting-man-u .search-row {
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  padding: 10px;
  background: var(--sec-bg-color);
  border-radius: 10px;
}

hr {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  background: var(--sec-bg-color);
  border: none;
  height: 2px;
}

.perm-title {
  margin-top: 80px;
  margin-left: 5px;
  font-size: 14px !important;
  color: var(--pri-color-light);
}

.slider {
  margin-left: auto;
  margin-right: 40px;
}

.slider img {
  max-width: 170px;
  max-height: 94px;

  margin-bottom: -25px;
}

.organ p {
  margin-top: 0px;
}

.perm-toggle {
  margin-top: 20px;
  margin-left: 5px;
}

.perm-toggle p {
  width: 80%;
}

.pincodes h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.pincodes {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.pincodes .left-row {
  padding: 0;
  width: 100% !important;
  border-bottom: 1px dashed var(--bor-color);
  padding-top: 10px;
  padding-bottom: 10px;
}

.pincodes .left-row .upload {
  margin: 0;
  margin-left: auto;
  height: 25px;
  width: 80px;
  padding: 5px;

  box-shadow: none;
  background: var(--sec-bg-color);
  border: none;
  color: var(--pri-color-light);
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 1px solid var(--bor-color);
}

.pincodes .left-row .upload:hover {
  box-shadow: none !important;
  background: var(--bg-color);
}

.pincodes .ic {
  width: 14px;
  height: 14px;
}

.generated-reports {
  padding-top: 20px;
  padding-bottom: 15px;
  height: calc(100vh - 75px);
  width: 100%;
  min-width: calc(100vw - 291px);
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
  background: var(--bg-color);
  padding-right: 20px;
}

.all-reports-table {
  width: 100%;
  border: 1px solid var(--bor-color);
}

.gen-rep-row {
  cursor: pointer;
}

.recent-reports-grid {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}

.generated-report {
  width: 240px;
  height: 200px;
  border: 1px solid var(--bor-color);
  border-radius: 10px;
  background: var(--sec-bg-color);
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.fake-report {
  background: var(--bg-color);
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 100%;
  overflow: hidden;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.fake-report .icon {
  stroke: var(--pri-color-light);
  align-self: center;
  top: 80px;
  background: var(--bg-color);
  width: 35px;
  height: 30px;
  padding: 5px;
  position: absolute;
}

.fake-report .row-2 {
  margin-bottom: 10px;
}

.fake-report h2 {
  font-size: 9px;
  margin-top: 3px;
}

.fake-report h3 {
  font-size: 8px;
}

.fake-report hr {
  background: var(--bor-color);
  margin-right: 100%;
  margin-top: 5px;
  height: 2px;
  margin-bottom: 4px;
  margin-left: 5px;
  align-self: left !important;
}

.fake-report hr:nth-of-type(1) {
  width: 70%;
}

.fake-report hr:nth-of-type(2) {
  width: 90%;
}

.fake-report hr:nth-of-type(3) {
  width: 80%;
}

.fake-report hr:nth-of-type(4) {
  width: 90%;
}

.fake-report hr:nth-of-type(5) {
  width: 80%;
}

.fake-report hr:nth-of-type(6) {
  width: 70%;
}

.fake-report hr:nth-of-type(7) {
  width: 90%;
}

.fake-report hr:nth-of-type(8) {
  width: 80%;
}

.fake-report hr:nth-of-type(9) {
  width: 90%;
}

.fake-report hr:nth-of-type(10) {
  width: 60%;
}

.gen-rep-desc {
  background: var(--bg-color);
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid var(--bor-color);
  height: 30px;
  padding: 10px;
}

.gen-rep-desc h3 {
  color: var(--pri-color);
  font-weight: 700;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
}

.gen-rep-desc h4 {
  color: var(--pri-color-light);
  font-weight: 500;
  font-size: 1px;
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.cwe-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}
.cwe-grid p {
  text-align: justify !important;
}

.cwe-grid-item {
  width: 100%;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-left: 0;
  position: relative;
}

.cwe-grid-item ::-webkit-scrollbar {
  background: var(--bor-color);
}

.cwe-grid-item .left-row {
  align-items: flex-start !important;
  padding-left: 0;
}

.cwe-grid-item .light-r,
.cwe-grid-item .light-y,
.cwe-grid-item .light-g,
.cwe-grid-item .light-gr {
  position: absolute;
  top: 20px;
  right: 0px;
}

.cwe-head {
  font-weight: 600;
  width: 100%;
  padding-right: 10px;
}

.cwe-nloc {
  margin-bottom: 5px;
  font-size: 15px !important;
}

.cwe-desc {
  line-height: 1.5;
  color: var(--pri-color) !important;
}

.cwe-len-buts {
  position: absolute;
  top: 6px;
  right: 40px;
}

.cwe-len-buts button {
  margin: 0;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.cwe-len-buts button hr {
  margin: 0;
  margin-top: 1px;
  height: 2px;
  padding: 0;
  background-color: var(--bor-color) !important;
}

.cwe-len-buts button:nth-of-type(1) {
  border: 1px solid var(--bor-color);
  padding: 5px;
  border-right: none;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.cwe-len-buts button:nth-of-type(1) hr:nth-of-type(2) {
  width: 50%;
}

.cwe-len-buts button:nth-of-type(1) hr:nth-of-type(3) {
  width: 0;
}
.cwe-len-buts button:nth-of-type(1) hr:nth-of-type(4) {
  width: 0;
}

.cwe-len-buts button:nth-of-type(2) {
  border: 1px solid var(--bor-color);
  padding: 5px;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cwe-len-buts button:nth-of-type(2) hr:nth-of-type(2) {
  width: 80%;
}

.cwe-len-buts button:nth-of-type(2) hr:nth-of-type(3) {
  width: 100;
}
.cwe-len-buts button:nth-of-type(2) hr:nth-of-type(4) {
  width: 60%;
}

.cwe-len-but-sel {
  background: var(--sec-color);
}

.pot-mit {
  margin-top: 10px;
  height: 160px;
  overflow-y: scroll;
}

.mitigation {
  border-top: 1px solid var(--bor-color);
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  margin: 0;
  align-items: flex-start !important;
}

.mitigation:hover {
  background: var(--sec-bg-color);
}

.mit-text {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--pri-color);
  line-height: 1.5;
  margin-top: -3.5px;
}

.mit-cwe {
  margin-left: auto;
  font-weight: 600;
}

.bucket-chev {
  margin-left: 10px;
  color: var(--pri-color-light);
  width: 18px;
  height: 18px;
  margin-top: -4px;
}

.report-subtitle {
  margin-top: 0px !important;
}

.mit-l {
  margin-left: -20px;
}

.closed-mit {
  white-space: nowrap;
}

.entropy {
  padding: 20px;
  margin-top: 10px;
  margin-left: -20px;
  border-radius: 10px;
  margin-right: 20px;
  width: 60%;
  background: var(--sec-bg-color);
}

.filter .chartt {
  margin-top: 20px;
  margin-bottom: 10px;
}

.filter .entropy {
  width: 460px;
  margin-left: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.entropy h2 {
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--pri-color-light);
}

.entropy h3 {
  color: var(--pri-color);
  display: flex;
  flex-direction: row;
  line-height: 1.7;
}

.entropy h3 b {
  margin-left: auto;
  font-weight: 500;
}

.report-prev .base-metadata {
  margin-top: 10px;
  border-left: 2px solid var(--bor-color);
  padding: 0;
  padding-left: 10px;
}

.report-prev .base-metadata div {
  margin-bottom: 15px;
}

.base-metadata {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 20px;
}

.base-metadata div {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
}

.base-metadata .user {
  position: absolute;
  left: 0;
  width: 26px;
  height: 26px;
  stroke-width: 1.5px !important;
  color: var(--pri-color-light);
}
.base-metadata h1 {
  margin-left: 3px;
}

.base-metadata h3 {
  color: var(--pri-color);
  margin: 0 !important;
  line-height: 1;

  font-weight: 500;
  margin-left: 35px !important;
}

.base-metadata h4 {
  margin: 0;
  margin-top: 5px;
  color: var(--pri-color-light);
  font-weight: 400;
  margin-left: 35px !important;
}

.sidebar hr {
  margin: 5px;
  width: 75%;
  background: var(--sec-bg-color);
  height: 2px;
  align-self: center;
}

.page-num {
  position: absolute;
  bottom: 40px;
  right: 60px;
}

.setting-man-u .searchbar {
  width: 400px;
  margin: 0;
  margin-right: 20px;
  margin-left: -2px;
  background: var(--sec-bg-color);
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  border: 1px solid var(--bor-color);
}

.login-page-old .searchbar {
  margin-left: 0px;
}

.update-url .upload {
  width: 100px;
  height: 42px;
  margin: 0;
  margin-bottom: 5px;
}

.update-url .next-off {
  width: 100px;
  height: 42px;
  margin: 0;
  margin-bottom: 5px;
}

.search-row-2 {
  align-items: flex-start !important;
}

.update-warn {
  margin-left: 5px;
}

.set-side-title {
  margin-bottom: 20px;
  margin-top: 20px;
}

.login-page-old .set-side-title {
  margin: 0;
  margin-left: -8px;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 15px !important;
}

.paginations {
  display: flex;
  margin-top: 10px;
}
.paginations .sec-button {
  color: var(--pri-color-light);
  background: none;
  margin: 0;
  border-left: none;
  border-radius: 0px;
  height: 30px;
  width: 30px;
  padding: 0;
}

.paginations .sec-button:nth-of-type(1) {
  border-left: 1px solid var(--bor-color);
}

.current-pagination {
  background: var(--sec-color) !important;
  color: var(--bg-color) !important;
  border-color: var(--sec-color) !important;
}

.bap-table {
  width: 100%;
}

.upload-options-modal {
  max-height: none !important;
}

.skip-but {
  margin-top: 3px;
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  cursor: pointer;
  color: var(--pri-color-light);
  margin-right: -10px;
  transition: 0.2s;
}

.skip-but:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.upload-options {
  padding-right: 30px;
  font-size: 14px;
  font-weight: 600;
  margin-left: -20px;
}

.upload-options .upload,
.upload-options .next-off {
  margin-right: -10px !important;
  transition: none;
}

.upload-options h3 {
  color: var(--pri-color);

  margin-bottom: 15px;
  font-size: 15px;
  line-height: 1.2;

  padding-top: 10px;
  padding-bottom: 10px;
}

.upload-options .row h3 {
  width: 100%;
  height: 19px;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.upload-options .row .sec-button {
  min-width: 32px;
  margin-right: 10px;
}

.upload-options .row {
  align-items: center;
  margin-bottom: 10px;
}

.page-out {
  margin-left: 20px;
  margin-top: 18px;
}

.upload-options .left-row {
  margin-top: -20px;
  margin-bottom: -10px;
}

.opt-group {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  row-gap: 0px;
  height: 180px;
}

@keyframes fade {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes left {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-400px);
  }
}

@keyframes right {
  from {
    transform: translateX(400px);
  }
  to {
    transform: none;
  }
}

.upload-options ol {
  width: 400px !important;
  height: 220px;
  margin-top: 0px;
}

.generated-report .report-title h2 {
  width: 60px;

  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spinner {
  animation: spin 3s infinite linear;
  color: var(--sec-color);
  height: 70%;
  margin-top: 5px;
  margin-left: 7px;
  width: 70%;
  stroke-width: 2.5px;
}

.failed-spinner {
  animation: none !important;
  color: var(--contrast-color);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin-middle {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.progress-popup .CWSS-chart {
  margin-left: 1px;
  transform: translateY(-5px);
}

.choose-file {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

table .row-2 {
  margin-top: 0px !important;
  cursor: pointer;
  transition: 0.2s;
}

table th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-chevrons {
  margin-top: -6px;
  margin-left: 5px;
  margin-right: auto;
}

.table-chevron {
  height: 9px;
}

.table-chevron-icon {
  width: 15px;
  height: 15px;
  stroke: var(--pri-color);
  stroke-width: 3px;
}

.table-chevron-icon-dim {
  stroke: var(--pri-color-light);
}

.integration {
  background: none;
  width: 40.5%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: default;
  max-width: 300px;
}

.integration .row {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.integration img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.integration h2 {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
}

.integration p {
  line-height: 1.4;
}

.integration .slider {
  margin-left: auto !important;
  margin-right: 40px;
  margin-top: 10px;
}

.integration .user {
  margin-top: 12px;
  transition: 0.2s;
  cursor: pointer;
}

.integration .user:hover {
  stroke: var(--sec-color);
}

.integration .row:nth-of-type(2) {
  position: absolute;
  bottom: 0;
}

/* dashboard */
.dashboard-main {
  position: relative;
  display: flex;
  flex-direction: row;
  background: black;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 40px);
  margin-top: 40px;
}

.dashboard-left {
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--bor-color);
  padding: 20px;
  overflow-y: scroll;
}

.dashboard-left h2 {
  font-size: 14px;
}

.organization-health {
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 700px;
  z-index: 0;
}

.ring {
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 20px solid var(--bg-color);
  width: 900px;
  height: 900px;
}

.dashboard-right {
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: auto;
}

.dashboard-right h2 {
  font-size: 14px;
  font-weight: 400;
}

.dash-right-title {
  margin-top: 20px;
  font-weight: 500 !important;
  margin-left: 10px;
  font-size: 15px;
}

.dash-right {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 100;
}

.dash-right .left-row {
  margin-bottom: 10px;
}

.dash-right .left-row h3 {
  text-align: right;
  width: 200px;
  margin-right: 10px;
}

.your-day {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.your-day h2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

.day-chart-holder {
  margin-right: 10px;
  height: 50px;
  width: 50px;
}

.analyzations-today {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding-left: 12px;
}

.analyzations-today h2 {
  color: var(--sec-color);
  font-size: 22px;
  font-weight: 700;
  margin-right: 22px;
}

.threat-overtime {
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 20px;
  margin-bottom: 20px;
  background: var(--bg-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.threat-title {
  margin-top: auto !important;
  margin-bottom: 10px !important;
}

.threat-overtime-holder {
  height: 160px;
  width: 240px;
  position: relative;
  margin-bottom: 70px;
}

.threat-overtime h2,
.user-activity h2 {
  font-weight: 500;
  font-size: 14px;
}

.threat-overtime .left-row,
.user-activity .left-row {
  justify-content: flex-start;
  padding: 0;
  margin-bottom: 10px;
}

.threat-overtime .upload {
  margin: 0;
  background: none;
  box-shadow: none;
  color: var(--sec-color);
  padding: 5px;
  width: 50px;
  margin-left: auto;
  border-radius: 4px;
}

.threat-overtime .upload:hover {
  box-shadow: none !important;
}

.threat-overtime .next-off {
  padding: 5px;
  width: 50px;
  margin: 0;
  margin-left: 5px;
}

.user-activity {
  margin-bottom: 0px;
  padding: 20px;
  background: var(--bg-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.embedded-system-map {
  background: black;
  width: 100%;
  height: 100%;
  position: relative;
  background-position: 0 0, 80px 80px;
  background-size: 10px 10px;
}

.biggest-threats {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 240px;
  overflow-x: hidden;
  padding: 10px;
}

.big-threat {
  background: var(--bg-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
}

.big-threat .CWSS-chart-holder {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100px !important;
  height: 100px !important;
}

.big-threat .left-row {
  align-items: center;
  justify-content: flex-start;
  margin-left: -22px;
}

.big-threat h2 {
  margin-top: -15px;
  font-size: 14px;
  font-weight: 400;
}

#three-graph {
  position: relative;
  width: 100%;
  z-index: 0;
}

.login-with-google {
  width: 109%;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(71, 63, 63, 0.3) 0px 1px 3px -1px;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 14px;
  transition: 0.2s;
  background: var(--sec-color);
  color: var(--bg-color);
  padding-top: 13px;
  padding-bottom: 13px;
}

.login-with-google:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.login-with-google img {
  width: 18px;
  height: 18px;
  margin-right: 50px;
}

.assets {
  position: absolute;
  z-index: 100;
  padding: 20px;
  width: 200px;
  height: calc(100vh - 30px);
  display: flex;
  flex-direction: column;
  z-index: 110 !important;
}

.assets h3 {
  font-weight: 600;
  margin-bottom: 28px;
  margin-top: -5px;
}

.assets .left-row {
  padding: 5px;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  margin-left: -8px;
  border: 1px solid transparent;
}

.assets .left-row:hover {
  background: var(--bg-color) !important;
}

@keyframes slide-in {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fade-in {
  0% {
    transform: translateX(-200px);
  }

  100% {
    transform: none;
  }
}

.assets h2 {
  color: var(--pri-color);

  font-size: 14px;
  font-weight: 300;
}

.assets .left-row h3 {
  margin: 0;
  margin-left: 5px;
}

.assets .ic {
  color: var(--pri-color);
  stroke-width: 1.7px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.device-name-top {
  position: absolute;
  top: 15px;
  z-index: 100;
  font-weight: 600;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes slide-down2 {
  0% {
    transform: translate(-50%, -100px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.red-strip {
  background: var(--contrast-color);
  height: 5px;
  border-radius: 5px;
  width: 30px;
  margin-right: 10px;
  margin-left: auto;
}

.yellow-strip {
  background: var(--yellow-color);
  height: 5px;
  border-radius: 5px;
  width: 30px;
  margin-right: 10px;
  margin-left: auto;
}

.green-strip {
  background: var(--green-color);
  height: 5px;
  border-radius: 5px;
  width: 30px;
  margin-right: 10px;
  margin-left: auto;
}

.zoom-buttons {
  position: absolute;
  bottom: 10px;
  right: 20px;
  z-index: 100;
}

.zoom-button {
  border: 1px solid var(--pri-color-light);
  border-radius: 5px;
  margin-top: 5px;
  height: 21px;
  width: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation-name: rev-slide-in;
}

.zoom-button:nth-of-type(1) {
  animation-duration: 0.5s;
}

.zoom-button:nth-of-type(2) {
  animation-duration: 0.6s;
}

.zoom-button:nth-of-type(3) {
  animation-duration: 0.7s;
}

@keyframes rev-slide-in {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: none;
  }
}

.zoom-button:hover {
  border: 1px solid var(--sec-color);
}

.zoom-button:hover .ic {
  stroke: var(--sec-color);
}

.zoom-button .ic {
  stroke: var(--pri-color-light);
  height: 13px;
  width: 13px;
  margin: 0;
}

.dark-wnew {
  animation: slide-down 0.9s;
}

.dark-logout {
  animation: slide-down 1s;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: none;
  }
}

.arrows-bot {
  left: 50%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100px;
  bottom: 40px;
  transform: translateX(-50%);
  justify-content: center;
}

.arrows-bot .zoom-button {
  animation-name: slide-in !important;
}

.cate-tooltip {
  position: absolute;
  border: 1px solid var(--bor-color);
  background: var(--bg-color);
  z-index: 100;
  top: 240px;
  left: 50%;
  transform: translate(7px, 6px);
  padding: 10px;
}

.no-bin-ass {
  animation: fade-bin 0.2s;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes fade-bin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cate-tooltip .light-r,
.cate-tooltip .light-y,
.cate-tooltip .light-g,
.cate-tooltip .light-gr {
  margin: 0;
  margin-right: 10px;
}

.cate-tooltip .left-row {
  padding-left: 0;
  margin-bottom: 0px;
}

.cate-tooltip h2 {
  color: var(--pri-color);
  font-size: 14px;
  line-height: 2;
}

.bot-arrow {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  z-index: 101;
  border-top: 10px solid var(--bg-color);
}

code span {
  cursor: text !important;
}

pre {
  background: transparent !important;
  cursor: text !important;
  overflow-x: hidden !important;
}

.bot-arrow2 {
  position: absolute;
  bottom: -12px;

  left: -1px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;

  border-top: 11px solid var(--bor-color);
}

.gree {
  transform: translate(-240px, -120px);
}

.yell {
  transform: translate(80px, -160px);
}

@keyframes fast-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tech {
  0% {
    opacity: 0;
    max-width: 0px;
    max-height: 0px;
  }
  100% {
    max-width: 200px;
    max-height: 60px;
  }
}

.tog-row {
  padding: 0;
  margin: 0;
  justify-content: space-between;
  border-bottom: 1px dashed var(--bor-color);
}

.toggle-header-popup h2 {
  font-size: 15px;
  color: var(--pri-color);
}

.toggle-header-popup h3 {
  font-size: 14px;
  color: var(--pri-color-light);
  font-weight: 400;
  margin-bottom: 10px;
}

.tog-row h3 {
  color: var(--pri-color);
  margin-bottom: 0px;
}

.inc-but {
  padding: 10px;
  font-weight: 600;
  width: 80px;
  color: var(--pri-color-light);
}

.inc-but:hover {
  background: var(--sec-bg-color);
}

.rem-but {
  padding: 10px;
  font-weight: 600;
  background: var(--sec-color);
  color: var(--bg-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 80px;
}

.org-logo {
  border: 1px dashed var(--bor-color);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: -40px;
}

.org-logo h3 {
  font-weight: 600;
}

footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100vw;
  background: var(--bg-color);
  left: 0;
  border-top: 1px solid var(--bor-color);
  align-items: center;
  height: 25px;
  z-index: 101;
}

footer .alpha {
  margin: 0;
  margin-left: 55px;
  font-size: 11px;
}

footer span {
  margin-right: 30px;
  font-size: 11px;
  font-weight: 400;
  color: var(--pri-color-light);
  text-decoration: none;
}

.create-user-modal {
  box-shadow: none !important;
  margin: none !important;
  width: 90% !important;
  height: 100% !important;
  margin-top: -20px;
}

.create-user-modal-top {
  min-width: 310px;
  max-width: 310px !important;
  max-height: 400px;
  min-height: 400px;
}

.update-user-modal-top {
  min-width: 210px;
  max-width: 210px !important;
  max-height: 140px !important;
  min-height: 140px !important;
}

.create-user-modal .upload {
  width: 108%;
  margin-top: 20px;
}

.update-user-modal-top .upload {
  margin-top: 0px;
}

.x-user {
  position: absolute;
  top: 10px;
  right: -10px;
  background: none;
  border: none;
}

.x-user:hover {
  box-shadow: none;
}

.create-user-modal .left-row {
  justify-content: flex-start;
  padding: 0;
  margin-left: 11px;
}

.select-role {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 122px;
  padding: 10px;
  margin-top: 5px;
  margin-right: 10px;
  border: 1px solid var(--bor-color);
}

.select-role:nth-of-type(2) {
  border: 1px solid var(--sec-color);
}

.select-role h4 {
  font-weight: 400;
}

.select-role:nth-of-type(2) h4 {
  color: var(--sec-color);
}

.select-role:nth-of-type(2) .user {
  stroke: var(--sec-color);
}

.x-user:hover .user {
  stroke: var(--sec-color);
}

.clamav {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: var(--sec-bg-color);
  padding: 20px;
}

.clamav h2 {
  color: var(--contrast-color);
  font-weight: 700;
}

.clamav p {
  margin-top: 5px;
  font-weight: 500;
}

.clamav .user {
  stroke: var(--contrast-color);
  margin-right: 5px;
}

.clamav .left-row {
  padding: 0;
}

.pin-next {
  position: absolute;
  top: 13px;
  right: 0px;
  width: 24px;
  height: 24px;
  padding: 0;
}

.low-on-pins {
  position: fixed;
  top: 10px;
  right: 10px;
  animation: right 0.5s;
  z-index: 800;
  background: var(--bg-color);
  padding: 20px;
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  border-radius: 10px;
}

.notifs {
  position: fixed;
  z-index: 800;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  bottom: 80px;
  right: 20px;
}

.to-clipboard {
  background: var(--sec-bg-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  border-left: 3px solid var(--sec-color);
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: right 0.5s;
  margin-top: 10px;
  transition: 0.5s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.to-clipboard h3 {
  margin: 0 !important;
  margin-top: 0px !important;
  margin-left: 10px !important;
  line-height: 1.4;
}

.lockout-span:hover {
  box-shadow: inset 0 -1.125em 0 var(--sec-color);
  color: #fff;
}

header span:hover {
  box-shadow: inset 0 -1.125em 0 var(--sec-color);
  color: #fff;
}
.pincodes span {
  cursor: pointer;
  transition: 0.2s;
}
.pincodes span:hover {
  box-shadow: inset 0 -1.125em 0 var(--sec-color);
  color: #fff;
}

.file-drop-modal span:hover {
  box-shadow: inset 0 -1.125em 0 var(--sec-color);
  color: #fff;
}

header span:focus {
  background: #fff;
  outline: none;
  background: var(--sec-color);
  color: #fff;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
}

footer span:hover {
  box-shadow: inset 0 -1.125em 0 var(--sec-color);
  color: #fff;
}

footer span:focus {
  background: #fff;
  outline: none;
  background: var(--sec-color);
  color: #fff;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
}

.deep-dive .content span {
  color: unset;
  text-decoration: unset;
  font-weight: unset;
  cursor: default;
}

.deep-dive .content span:hover {
  color: inherit;
  box-shadow: none;
}

.deep-dive .filter span {
  color: unset;
  text-decoration: unset;
  font-weight: unset;
  cursor: default;
}

.deep-dive .filter span:hover {
  color: inherit;
  box-shadow: none;
}

.pincodes span {
  font-size: 14px;
  text-decoration: none;
  color: var(--sec-color);
  font-weight: 600;
}

.pincodes span:hover {
  color: var(--bg-color);
}

.pincodes .left-row:last-of-type {
  border: none;
}

.alert {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.alert h4 {
  font-size: 11px;
  text-align: right;
  width: 120px;
  margin-left: auto;
}

.color-level-red {
  background: var(--contrast-color);
  height: auto;
  min-width: 4px;
  border-radius: 3px;
  margin-right: 10px;
}

.color-level-yellow {
  background: var(--yellow-color);
  height: auto;
  min-width: 4px;
  border-radius: 3px;
  margin-right: 10px;
}

.deep-dive .content {
  padding: 0;
}

.deep-dive .toggle-header .user {
  height: 14px;
  width: 14px;
  align-self: center;
  cursor: pointer;
}

.deep-dive .toggle-header .user:hover {
  stroke: var(--sec-color);
}

.dis-dec-head {
  height: 45px;
  padding: 5px;
  align-items: center;
  background: var(--sec-bg-color);
  margin: 0;
  border-bottom: 1px solid var(--bor-color);
}

.dis-dec-head h3 {
  font-weight: 600;
  margin-left: 5px;
}

.deep-dive .toggle-header .user {
  margin-top: -4px;
}

.deep-dive .toggle-header-item {
  background: var(--sec-bg-color);
  border-top-color: var(--sec-bg-color);
  margin-top: -6px;
}

.deep-dive .sel-toggle-header-item {
  background: var(--bg-color);
  border-top-color: var(--sec-color);
}

.deep-dive .toggle-header-item .user {
  margin-top: 0px;
}

.deep-dive .filter .toggle-header {
  position: relative !important;
}

.deep-dive .filter {
  padding-top: 0 !important;
  min-height: 100vh !important;
}

.syntax {
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: calc(100vh - 176px);
  max-height: calc(100vh - 176px);
}

.syntax pre {
  overflow: hidden !important;
  cursor: text !important;
}

.openapi-syntax {
  min-height: calc(100vh - 173px) !important;
  max-height: calc(100vh - 173px) !important;
}

#asset-scroller {
  min-height: calc(100vh - 255px) !important;
  max-height: calc(100vh - 255px) !important;
}

.dev {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  gap: 20px;
}

.big-icon {
  width: 100px;
  height: 100px;
  stroke: var(--sec-bg-color);
  stroke-width: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cwe-bot-but {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  justify-content: flex-start;
  width: 40px;
}

.cwe-bot-but:hover h3 {
  color: var(--sec-color);
}

.cwe-more-pop .cwe-bot-but {
  cursor: default;
}

.cwe-more-pop .cwe-bot-but:hover .report-ic {
  stroke: var(--pri-color-light);
}

.cwe-more-pop .cwe-bot-but .report-ic {
  cursor: pointer;
}

.cwe-more-pop .cwe-bot-but .report-ic:hover {
  stroke: var(--sec-color);
}

.cwe-bot-but:hover .report-ic {
  stroke: var(--sec-color);
}

.cwe-bot-but .report-ic {
  stroke: var(--pri-color-light);
  margin-right: 3px;
  height: 16px;
  width: 16px;
}

.cwe-more-pop {
  width: 100%;
  border-radius: 10px;
  top: 0;
  left: 0px;
  z-index: 200;
  background: var(--bg-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  overflow: hidden;
  border: 1px solid var(--bor-color);
}

.cwe-more-pop ol {
  overflow-y: scroll;
  max-height: 200px;
  margin: 0;
  margin-top: -6px;
  padding-top: 6px;
  font-size: 14px;
  padding-left: 20px;
}

.cwe-more-pop li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.file-cat-head {
  cursor: pointer;
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}

.file-cat-head {
  padding: 0;
  padding-left: 40px;
  padding-right: 30px;
  width: calc(100% - 35px);
  align-items: center;
  margin: 0;
}

.file-cat-head .user {
  width: 18px;
  height: 18px;
  margin-top: 5px;
}

.file-cat-head .user:nth-of-type(2):hover {
  stroke: var(--sec-color);
}

.file-cat-head:hover {
  background: var(--sec-bg-color) !important;
}

.list-of-files .left-row {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 44px;
  margin: 0;
  cursor: pointer;
  width: calc(100% - 50px);
  padding-right: 20px;
}

.list-of-files .left-row .light-r,
.list-of-files .left-row .light-y,
.list-of-files .left-row .light-g,
.list-of-files .left-row .light-gr {
  margin: 0;
  margin-right: 10px;
}

.list-of-files .left-row:hover {
  background: var(--sec-bg-color) !important;
}

.list-of-files h2 {
  font-size: 14px;
  margin-right: 10px;
  margin-left: auto;
  color: var(--pri-color-light);
}

.list-of-files h3 {
  font-size: 14px;
  color: var(--pri-color);
  font-weight: 500;
}

.org-head-side {
  height: 33px;

  width: 100%;
}

header .row {
  margin-right: 10px;
  padding-right: 10px;
  border-radius: 0;
  border-right: 2px solid var(--sec-bg-color);
}

.org-users {
  display: flex;

  padding: 2px;

  height: 25px;
  align-items: center;
}

.org-users .user {
  margin-right: 0px;
}

.collection {
  position: relative;
}

.dotted-left {
  position: absolute;
  top: 30px;
  height: calc(100% - 30px);
  border-left: 2px solid var(--sec-bg-color);
  z-index: 20;
  left: 28px;
}

.collection-options {
  position: absolute;
  top: 25px;
  right: 10px;
  border-radius: 5px;
  border: 1px solid var(--bor-color);
  z-index: 11;
  width: 160px;
  background: var(--bg-color);
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.collection-options h3 {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  color: var(--pri-color);
}

.sel-col-opt {
  color: var(--sec-color) !important;
  background: var(--sec-bg-color);
}

.collection-options h3:hover {
  background: var(--sec-bg-color);
}

.collection-options .route-bg:hover {
  background: var(--sec-bg-color);
}

.top-of-content {
  align-items: center;
  margin: 0;
  width: calc(100% - 40px);
  margin-bottom: -10px;
  margin-top: 20px;
  align-items: center;
}

.top-of-content h3 {
  color: var(--pri-color-light);
  font-weight: 600;
}

.top-of-content .user {
  margin-right: 5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 5px;
}

.top-of-content .upload {
  margin-top: 0;
  height: 30px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.top-of-content .upload:nth-of-type(1) {
  margin-left: auto;
  margin-right: 10px;
}

.col-meta h3 {
  padding: 5px;
  padding-right: 5px;
  border-left: 2px solid var(--sec-bg-color);
}

.col-meta h3:first-of-type {
  border-left: none;
}

.day-user {
  background-color: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  margin-right: 10px;
  border-radius: 100%;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-user .user {
  width: 16px;
  height: 16px;
}

.day-event {
  margin-bottom: 10px;
}

.day-event p {
  text-align: left;
  margin-bottom: 3px;
  font-size: 14px;
}

textarea {
  resize: none;
  border: none;
  outline: none;
  color: var(--pri-color);
  line-height: 1.5;
}

@keyframes opac-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.request-header {
  align-items: center;
  margin-top: 0;
}

.request-header .sec-button {
  margin: 0;
  margin-left: 10px;
  min-width: 60px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  margin-right: 10px;
}

.request-header .sec-button h3 {
  color: var(--pri-color-light);
  margin-right: 3px;
  font-weight: 00;
  font-size: 14px;
}

.request-header .sec-button .user {
  margin-top: 0px;
}

.request-header h3 {
  color: var(--pri-color);
  margin-right: 8px;
  font-weight: 500;
  font-size: 14px;
}

.request-header h4 {
  font-size: 14px;
  font-weight: 400;
}

.request-header .upload {
  margin: 0;
  margin-left: auto;
  margin-right: 10px;
  height: 34px;
  width: 62px;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.req-key {
  width: 30%;
}

.req-key h2 {
  font-size: 15px;
}

.req-row {
  margin-top: 20px;
  margin-top: 20px;
  align-items: flex-start !important;
}

.req-field {
  width: 70%;
  padding-right: 20px;
}

.req-field input {
  background: var(--sec-bg-color);
}

.req-field textarea {
  background: var(--sec-bg-color);
  width: calc(100% - 20px);
  height: 300px;
  padding: 10px;
  border: 1px solid var(--bor-color);
  border-radius: 5px;
}

.res-key {
  width: 30%;
  font-size: 14px !important;
}

.res-field {
  width: 70%;
}

.res-field h3 {
  font-weight: 600;
  margin-bottom: 5px;
}

.res-field textarea {
  background: var(--sec-bg-color);
  width: calc(100% - 40px);
  height: 63px;
  padding: 10px;
  border: 1px solid var(--bor-color);
  border-radius: 5px;
  margin-bottom: 20px;
}

.res-header {
  align-items: flex-start !important;
}

.up-init {
  height: 16px;
  width: 100px;
  cursor: pointer;
  font-family: monospace;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--sec-bg-color);
  border: 1px solid var(--bor-color);
  padding: 10px;
  border-radius: 5px;
  position: relative;
  word-break: break-all;
  white-space: pre-wrap !important;
}

.up-init .user {
  margin-left: auto;
}

.res-filter .syntax {
  min-height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);
}

.select-deep .left-row {
  padding: 0;
  transform: none;
}

.select-deep .left-row {
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 20px;
}

.select-deep .left-row:hover {
  background: var(--sec-bg-color);
}

.select-deep .left-row .light-g,
.select-deep .left-row .light-y,
.select-deep .left-row .light-r {
  margin: 0 !important;
}

.select-deep .left-row h4 {
  margin-left: auto;
}

.select-deep {
  width: 300px;
  max-height: 300px;
  overflow-y: scroll;
}

embed {
  background: transparent;
}

body {
  background: var(--bg-color);
}

.head-row-high {
  background: var(--sec-bg-color);
}

header .row:hover h3 {
  color: var(--sec-color);
}

header .row:hover .user {
  stroke: var(--sec-color);
}

.add-org-but:hover {
  stroke: var(--sec-color);
}

.facility-name {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  padding-left: 0px;
}

.facility-name:hover {
  background: var(--sec-bg-color);
}

.dash-popup {
  position: absolute;
  right: 3px;
  top: -1px;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border: 1px solid var(--bor-color);
  transform: translateX(100%);
  background: var(--bg-color);

  padding-top: 10px;
  padding-bottom: 10px;
}

.dash-pop-cover {
  position: absolute;
  background: var(--bg-color);
  width: 3px;
  top: 0;
  left: -2px;
  height: 26px;
}

.collection-opt {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 10px;
}

.collection-opt:hover {
  background: var(--sec-bg-color);
}

.collection-opt .light-r,
.collection-opt .light-y,
.collection-opt .light-g,
.collection-opt .light-gr {
  margin: 0;
  margin-right: -3px;
  width: 8px;
  height: 8px;
}

.light2-r {
  background: var(--contrast-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.light2-y {
  background: var(--yellow-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.light2-g {
  background: var(--green-color);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.collection-opt .light-r {
  margin-left: auto;
}

.collection-opt h3 {
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.collection-opt .light-y,
.collection-opt .light-g {
  margin-left: 10px;
}

code {
  cursor: text !important;
  white-space: pre !important;
  word-break: break-all !important;
}

progress {
  border: none;
  border-radius: 10px;
  height: 5px;
  background: var(--bor-color);
}

progress::-moz-progress-bar {
  background: var(--sec-color);
}

progress::-webkit-progress-value {
  background: var(--sec-color);
}

.alarm-prog:-webkit-progress-value {
  background: var(--contrast-color) !important;
}

progress::-webkit-progress-bar {
  background: var(--bor-color);
}

.aegis-choice:hover {
  color: var(--pri-color) !important;
  cursor: pointer;
  font-weight: 400 !important;
}

.inv-hov {
  cursor: pointer;
}

.aegis-alert {
  z-index: 10;
  transition: 0.2s;
}

.aegis-line {
  z-index: 2;
  transition: 0.2s;
}

.inv-hov:hover .aegis-alert {
  transform: scale(1.1);
}

.inv-hov:hover .aegis-line {
  transform: scaleX(1.5);
}

.asset-gear:hover {
  stroke: var(--sec-color) !important;
}

.view-toggle-holder {
  cursor: pointer;
}

.view-toggle-holder:hover {
  background: var(--sec-bg-color);
}

.view-toggle-holder:hover .view-toggle {
  stroke: var(--sec-color);
}

.eula-content h1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}

.eula-content p {
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  margin-bottom: 20px;
}

.eula-content li {
  font-size: 14px;
  line-height: 1.4;
}

.eula-content ul {
  overflow: hidden;
  height: fit-content !important;
}

.more-hor:hover {
  stroke: var(--sec-color);
}

.react-pdf__Document {
  width: 595px;
}

.react-pdf__Page__canvas {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.react-pdf__Page {
  height: 850px;
}

.report-big-button {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    0s 1px 3px 1px rgb(60 64 67 / 15%);
  transition: 0.2s;
}

.report-big-button:hover {
  box-shadow: 0px 16px 10px 0px rgb(0 0 0 / 14%),
    0px 11px 18px 0px rgb(0 0 0 / 12%), 0px 13px 5px -1px rgb(0 0 0 / 20%) !important;
}

.MuiCheckbox-root {
  color: var(--pri-color) !important;
}

.download-eula:hover h3 {
  color: var(--sec-color);
}

.download-eula:hover .icon {
  stroke: var(--sec-color) !important;
}

.create-org-textarea {
  background: var(--sec-bg-color);
  width: calc(100%);
  height: 170px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid var(--bor-color);
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
}

.role-editor:hover .icon {
  stroke: var(--sec-color) !important;
}

.role-editor {
  position: relative;
  max-width: 100px !important;
  min-width: 100px !important;
  overflow: visible !important;
}

.role-editor:hover {
  color: var(--sec-color) !important;
}

@media only screen and (max-width: 1200px) {
  #dash-media {
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
  }
  .dash-media-child {
    width: calc(100% - 40px) !important;
    padding-left: 30px !important;
    overflow: visible !important;
  }
  #dash-media .dash-media-child:nth-of-type(2) {
    width: calc(100% - 70px) !important;
    margin-top: -60px;
  }

  #facility-media {
    flex-direction: column;
  }
  .facility-media-child {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  #asset-media {
    flex-direction: column;
  }
  .asset-media-child {
    max-width: 100% !important;
    min-width: 100% !important;
    margin-left: 0 !important;
  }

  .report-prev .base-metadata div {
    width: 100%;
  }
}

.score-g {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 100%;
  border-width: 4px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.score-g2 {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 100%;
  margin: 10px;
  border-width: 4px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.asset-tag:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--bg-color);
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 100%;
  z-index: 2;
}

.asset-tag:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid var(--bor-color);
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 100%;
  z-index: 1;
}

.asset-tag {
  background-color: var(--bg-color);
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1px 4px 1px 2px;
  background: var(--bg-color);
  outline: 1px solid var(--bor-color);
  position: relative;
  display: block;
  float: left;
  position: relative;
  font-size: 12px;
  height: 14px;
  margin-left: auto;
  margin-right: 10px;
  width: 20px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
}

.tag-bull {
  position: absolute;
  width: 4px;
  border-radius: 100%;
  height: 4px;
  background: var(--bor-color);
  left: -2px;
  top: 6px;
  z-index: 3;
}

.lds-dual-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.lds-dual-ring-center {
  margin-left: auto;
  margin-right: auto;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid var(--sec-color);
  border-color: var(--sec-color) transparent var(--sec-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-page-old {
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid var(--bor-color);
  border-width: 1px;
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page-old textarea {
  margin-bottom: 20px;
}

.login-page-old h1 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
}

.login-page-old input {
  background: var(--sec-bg-color);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--bor-color);
}

.login-page-old .row {
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.login-page-old .upload {
  margin: 0;
}

.in-progress-ring {
  display: flex;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 3px;
  width: 14px;
  height: 14px;
}

.in-progress-ring:after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--sec-color);
  border-color: var(--pri-color-light) transparent var(--pri-color-light)
    transparent;
  animation: lds-dual-ring 4s linear infinite;
}

.curr-analyze {
  color: var(--pri-color-light) !important;
  font-size: 12px !important;
  border: 1px solid var(--bor-color);
  padding: 5px;
  border-radius: 5px;
}

.in-progress-ring-big {
  display: flex;
  align-self: center;
  width: 40px;
  height: 40px;
  margin-left: 2px;
  margin-top: 2px;
}

.in-progress-ring-big:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid var(--sec-color);
  border-color: var(--pri-color-light) transparent var(--pri-color-light)
    transparent;
  animation: lds-dual-ring 5.2s linear infinite;
}

.unpaintable {
  width: 50%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.unpaintable a {
  color: var(--sec-color);
}

.syntax .unpaintable {
  position: relative !important;
  transform: none !important;
  left: unset;
  top: unset;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px !important;
  height: calc(100vh - 300px) !important;
}

.unpaintable .lds-dual-ring {
  display: flex;

  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.unpaintable .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 5px solid var(--bor-color);
  border-color: var(--bor-color) transparent var(--bor-color) transparent;
  animation: lds-dual-ring 4s linear infinite;
}

.unpaintable h3 {
  line-height: 1.4;
  font-size: 18px;
  text-align: center;
}

.threatai-associated-cwes {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.threatai-associated-cwes h3:hover {
  text-decoration: underline;
}
