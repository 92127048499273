.work-prep {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work-prep h1 {
  font-size: 32px;
  margin-bottom: 40px;
}

.work-prep .icon {
  width: 200px;
  height: 200px;
  stroke: var(--sec-bg-color);
}

.work-prep h2 {
  line-height: 1.4;
  text-align: center;
  color: var(--pri-color);
  font-size: 18px;
}

.work-prep p {
  line-height: 1.4;
  text-align: center;
  color: var(--pri-color-light);
  font-size: 16px;
}

.work-prep-anim {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 300px;
  justify-content: center;
  margin-bottom: 30px;
}

.work-prep-anim div {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  border: 10px solid var(--pri-color-light);
}

.work-prep-anim div:nth-of-type(1) {
  animation: grow-sqr 4s infinite 0.2s;
  transform-origin: bottom right;
}

.work-prep-anim div:nth-of-type(2) {
  animation: grow-sqr 4s infinite 0.4s;
  transform-origin: bottom left;
}

.work-prep-anim div:nth-of-type(3) {
  animation: grow-sqr 4s infinite 0.6s;
  transform-origin: top right;
}

.work-prep-anim div:nth-of-type(4) {
  animation: grow-sqr 4s infinite 0.8s;
  transform-origin: top left;
}

@keyframes grow-sqr {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.nvd_item {
  padding: 20px;
  border: 2px solid var(--sec-bg-color);
  border-radius: 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s;
}

.nvd_item:hover {
  background: var(--sec-bg-color);
}

.nvd_item h3 {
}

.nvd_item h2 {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
}

.nvd_item p {
  text-align: left;
  color: var(--pri-color-light);
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* 3/2/2023 Dashboard Update */

.org-cves-cwes {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 35px;
}

.org-cves {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.org-cves-cwes hr {
  width: 6px;
  height: 100px;
  padding: 0;
  margin: 0;
  background-color: var(--bg-color);
}

.org-cwes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.org-cves h2,
.org-cwes h2 {
  font-size: 32px;
  margin-bottom: 10px;
}
