.feature-toggles {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  max-width: 800px;
  align-self: center;
  gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid var(--sec-bg-color);
  padding-bottom: 40px;
}

.feature-toggles:last-of-type {
  border-bottom: none;
}

/* --- */

.feature-toggle {
  align-items: center;
  width: calc(100% - 60px);
  cursor: pointer;
  padding: 30px;
  border-radius: 20px;
  border: 2px solid var(--sec-bg-color);
}

.feature-toggle:hover {
  background: var(--sec-bg-color);
}

.feature-toggle-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.feature-toggle-header h1 {
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  margin-right: auto;
}

.feature-toggle p {
  font-size: 14px;
  line-height: 1.5;
  color: var(--pri-color-light);
  text-align: left;
}

.feature-toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
}

.feature-toggle-switch span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bor-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}

.feature-toggle-switch span:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: var(--pri-color-light);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.feature-toggle-switch-on span {
  background-color: #2196f3;
}

.feature-toggle-switch-on span:before {
  background-color: var(--pri-color);
}

.feature-toggle-switch-on span:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* --- */

.feature-toggle svg {
  margin-left: auto;
  width: 30px;
  height: 30px;
}

btn.min {
  background: red;
}
.btn:after {
  content: attr(data-active-icon);
  font-family: "FontAwesome";
}
.btn.min:after {
  content: attr(data-inactive-icon);
  font-family: "FontAwesome";
}
